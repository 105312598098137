/* not good. josh why did you do this */
* {
    font-family: 'w95fa';
    color: black;
}

/* good */
.container {
    background-color: #088484;
    position: absolute;
    height: 100%;
    width: 100%;
}


/* List of references to icon locations. */
.start {
    background-image: url("../resources/taskbarStartImage.png");
}

.myComputer {
    background-image: url("../resources/desktopicons//myDesktop.png");
}

.networkNeighborhood {
    background-image: url("../resources/desktopicons/NetworkNeighborhood.png");
}

.recycle {
    background-image: url("../resources/desktopicons/recycle.png");
}

.minesweeper {
    background-image: url("../resources/desktopicons/minesweeper.png");
}

.interspaceNavigator {
    background-image: url("../resources/desktopicons/interspaceNavigator.png");
}

/* good */
.taskBar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #c3c3c3;
    border-top: solid #f0fdff 3px;
}

/* good */
.taskBarIcon {
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
}

.smaller-tab-text {
    font-size: 23px;
}



.recruitmentTab {
    width: 600px !important;
}

/* good */
/* requires that draggable has preset values of positionOffset={{ x: '-50%', y: '-50%' }} */
.tabDefaults {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 500px;
    height: 50vh;
    min-width: 350px;
    width: 80vw;
    background-color: #c3c3c3;
    overflow: hidden !important;
}

.infoTab {
    min-width: 500px !important;
    width: 40vw !important;
}

.project-text {
    font-size: 22px !important;
}

.tab-text {
    font-size: 30px;
}

.prize-bullet {
    margin: 10px;
    font-size: 25px;
}

.smallTabDefaults {
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 200px;
    height: 50vh;
    min-width: 360px;
    width: 62vw;
    font-size: 40px;
    background-color: #c3c3c3;
    overflow: hidden;
}

.taskBarButton {
    height: 40px;
    width: 30vw;
    min-width: 50px;
    margin: 4px;
    display: flexbox;
    position: relative;
    background-color: #c3c3c3;
    padding: 7px;
}

.surveyResults * {
    font-family: 'sans-serif *';
}

.taskBarButtonContent {
    position: relative;
    margin-left: 5px;
    font-size: 25px;
    font-weight: 800;
    text-align: left;
}

.taskBarButtonIcon {
    padding-left: 22px !important;
}

.htmlFormContainer{
    background-color: white;
}

.tabContents {
    height: 93%;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

.htmlForm {
    padding: .5vw !important;
    padding-right: 3vw !important;
}

.windows95Tab {
    border-top: solid 4px white;
    border-left: solid 4px white;
    border-right: solid 4px black;
    border-bottom: solid 4px black;
}

.windows95TabInverse {
    border-top: solid 4px black;
    border-left: solid 4px black;
    border-right: solid 4px white;
    border-bottom: solid 4px white;
}

.skillscreenertab {
    width: 80vw;
    min-width: 350px;
}

.skillscreener2tab {
    width: 60vw;
    height: 50vh;
    min-width: 400px;
}

.tabHeadingBar {
    height: 40px;
    background-color: #000082;
    color: white;
    margin: 3px;
    font-size: 30px;
}

div {
    padding: 0px;
}


.surveyLookup {
    padding: 1vw !important;
}

.surveyLookup .results {
    margin-top: 3vw;
}

.surveyLookup .heading {
    font-weight: bold;
}

.passwordFormTab {
    height: 15vh;
    min-height: 220px;
    min-width: 300px;
    width: 50vw;
}

.passwordFormText label {
    font-size: 40px;
    padding: 5px;
    height: 40px !important;
    display: block;
}

.passwordFormText {
    display: inline;
}

.tabHeading {
    color: white;
    padding: 7px;
}

input {
    font-size: 25px;
    padding: 5px;
    margin-left: 5px;
}

.submit {
    margin: 5px;
    height: 40px;
    cursor: pointer;
}

.passwordInput {
    width: 200px !important;
    height: 40px;
}

.recruitment {
    height: 500px;
    width: 500px;
    background-color: #c3c3c3;
    top: 100px;
    left: 500px;
}

.consentText {
    margin: 5px;
    font-size: 25px;
    line-height: 30px;
}

.button {
    height: 80px;
    width: 150px;
    color: white;
    font-size: 30px;
    position: absolute;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scheduler-text {
    margin: 30px;
    font-size: 25px;
}

.scheduler-text-large {
    font-size: 40px !important;
    margin: 30px;
}

.scrollable {
    height: 80%;
    overflow-y: scroll !important;
}



.consentingRedirect {
    height: 400px;
    width: 600px;
}

.scheduler-button {
    background-color: green;
    color: white !important;
    margin: 30px;
    font-size: 50px !important;
    display: block;
    text-align: center !important;
}

.tabDefaultButton {
    height: 70px;
    min-width: 150px;
    text-align: center;
    color: black;
    font-size: 30px;
    margin: 10px;
}

.tabText {
    font-size: 35px;
    text-align: center;
    margin-top: 10px;
}

.largeTabText {
    font-size: 55px;
    text-align: center;
    margin-top: 10px;
}

*{
    line-height: normal;
}


.namePickerTab{
    height: 450px;
    min-height: 400px;
    width: 30vw;
    min-width: 450px;
}

.blue {
    color: #000082;
    text-align: center;
}

.margins {
    margin: 5px;
    font-size: 20px;
}

.stampFormat {
    height: 50px;
    float: right;
}

.overflowX {
    width: 90%;
    overflow-x: scroll;
}

.leftButton {
    background-color: green;
    color: white;
    left: 10%;
    bottom: 20px;

}

.rightButton {
    background-color: red;
    color: white;
    right: 10%;
    bottom: 20px;
}

.rightButton:hover {
    background-color: darkred;
}

.leftButton:hover {
    background-color: darkgreen;
}

.centerText {
    text-align: center;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.nudgeLower {
    margin-top: 30px;
}

input {
    border: solid black 1px;
}

.TaskBarButton:hover {
    background-color: grey;
}

.ButtonSelected {}

.noIcon {
    margin-left: 0px;
    text-align: left;
}

.clockButton {
    height: 40px;
    width: 100px;
    float: right;
    z-index: 2;
    padding-right: 5px;
    color: black;
    right: 0px;
    text-align: center;
    transform: translateY(10%);
}

.clockBorderStyling {
    border-top: solid 4px gray;
    border-left: solid 4px gray;
    border-right: solid 4px white;
    border-bottom: solid 4px white;
}

.clockStyle {
    transform: translateY(50%);
}

.desktopIcons {
    height: 95px;
    margin-bottom: 15px;
    margin-left: 10px;
    width: 105px;
    margin-top: 3px;
}

.desktopIconsImage {
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
    margin: auto;
}

.desktopIconTextStyling {
    color: #fdfefe;
    font-size: 17px;
    padding-top: 10px;
    font-weight: 0px;
    line-height: 1.1;
    text-align: center;
    letter-spacing: 1;
}

.wrapped {
    overflow-wrap: break-word;
}

.declineCheckboxes {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
    margin-right: 10px;
}

.surveyUrlsForm {
    display:grid;
    grid-template-columns: max-content auto;
    grid-gap:5px;
    width: 100%;
}

.surveyUrlsForm input {
    align-items: stretch;
}

.tabSurveyUrls {
    min-height: auto;
    max-height: 80vh;
    height: auto;
}

.scroll {
    height: 100vh;
    overflow-y: scroll;
}

.container-flex {
    display: flex;
}

.col {
    flex-direction: column;
}

@media only screen {

    .pdf {
        height: 50vh;
        width: 79vw;
    }
    .largeTabText {
        font-size: 30px;
    }
    /* .passwordFormTab {
        height: 30vh;
    } */
}

@-moz-document url-prefix(){

    .taskBarButtonContent {
       top: -5px !important;
    }
}

@media print {
    .scroll{
     width: 100%;
     height: 100%;
    }
  .visible-print{
    display: block;
    width: auto;
    height: auto;
    overflow: visible;  
   }
  }

.eligibility-checkbox{
    height: 20px;
    width: 20px;
}


.timeInfo {
    width: clamp(700px, 70vw, 850px);
    height: clamp(800px, 80vh, 800px);
}

.consentingTab {
    width: clamp(700px, 70vw, 850px);
    height: clamp(660px, 80vh, 700px);
}

.projectEligiblity {
    width: clamp(600px, 70vw, 600px);
    height: clamp(550px, 80vh, 600px);
}

.schedulertab {
    width: clamp(500px, 70vw, 500px);
    height: 300px;
    min-height: 300px;
}

@media (max-width: 768px) {
    .timeInfo {
        width: 90vw;  /* Make it wider on small screens */
        height: 85vh;  /* Reduce height slightly */
    }
    .consentingTab {
        width: 90vw;  /* Make it wider on small screens */
        height: 75vh;  /* Reduce height slightly */
    }

    .projectEligiblity {
        width: 90vw;
        height: 80vh;
    }

    .schedulertab {
        width: 90vw;
    }

} 

.grayButton {
    color: lightgray;
    background-color: gray;
}

.grayButton:hover {
    color: lightgray;
    background-color: gray;
}