@font-face {
  font-family: 'w95fa';
  src: local('w95fa'), url(./fonts/w95fa.woff) format('woff');
}

body {
  margin: 0;
  font-family: 'w95fa', 'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'w95fa', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* // reset everything */
*, *::before,*::after {
  box-sizing: border-box;
}

